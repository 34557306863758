import { UilAngleLeftB, UilAngleRightB } from "@iconscout/react-unicons";
import { Carousel } from "@mantine/carousel";
import Image from "next/image";
import Link from "next/link";
import { FC, useMemo } from "react";

import { AnimatedIcon } from "../../components/Button";
import {
  Testimonial,
  TestimonialCategory,
  TestimonialList,
} from "../../content/testimonials";
import { useIsMobile } from "../../hooks/useIsMobile";
import { PostType } from "../../utils/blogApi";
import { classNames } from "../../utils/classNames";
import { FrecWebPath } from "../../utils/paths";

export const Testimonials: FC<{ posts: PostType[] }> = ({ posts }) => {
  const isMobile = useIsMobile();
  const testimonials = useMemo(() => {
    return TestimonialList.filter((t) =>
      posts.some(
        (p) => p.categories.includes(TestimonialCategory) && t.postId === p.ID
      )
    );
  }, [posts]);
  return testimonials.length > 0 ? (
    <div className="grid grid-cols-[auto_minmax(0,_86rem)_auto] py-12 bg-frecBeige">
      {/* row */}
      <div className="">{/* empty */}</div>
      <div className="lg:mx-12">
        {isMobile ? (
          <Carousel
            align="center"
            classNames={{
              control:
                "bg-frecBeige/50 w-10 h-10 !border-frecDarkBeige hide-inactive",
            }}
            inViewThreshold={1}
            nextControlIcon={<UilAngleRightB size={24} className="ml-1" />}
            nextControlProps={{
              "aria-label": "Next",
            }}
            previousControlIcon={<UilAngleLeftB size={24} className="mr-1" />}
            previousControlProps={{
              "aria-label": "Previous",
            }}
            slidesToScroll={1}
            slideSize="80%"
            slideGap="sm"
            withControls={true}
            controlsOffset="xs"
          >
            {testimonials.map((testimonial, index) => (
              <Carousel.Slide key={"slide-" + index}>
                <TestimonialCard
                  testimonial={testimonial}
                  first={index === 0}
                  last={index === testimonials.length - 1}
                />
              </Carousel.Slide>
            ))}
          </Carousel>
        ) : (
          <div className="flex">
            {testimonials.map((testimonial, index) => (
              <TestimonialCard
                testimonial={testimonial}
                key={"slide-" + index}
                first={index === 0}
                last={index === testimonials.length - 1}
              />
            ))}
          </div>
        )}
      </div>
      <div className="">{/* empty */}</div>
    </div>
  ) : null;
};

export const TestimonialCard: FC<{
  testimonial: Testimonial;
  first: boolean;
  last: boolean;
}> = ({ testimonial, first, last }) => {
  return (
    <Link
      href={`${FrecWebPath.blog}/${testimonial.url}`}
      className="group w-full"
    >
      <div
        className={classNames(
          "inline-flex flex-col items-start justify-start gap-6 p-6 bg-frecBeige",
          first ? "pl-0" : "",
          last ? "pr-0" : "border-r dotted-v light"
        )}
      >
        <div className="flex flex-col items-start self-stretch justify-start h-56 gap-6">
          <div className="inline-flex items-center justify-start gap-4">
            {testimonial.image ? (
              <Image
                src={testimonial.image}
                width={128}
                height={128}
                className="rounded-full w-14 h-14 bg-frecDarkBeige"
                alt=""
              />
            ) : null}
            <div className="inline-flex flex-col items-start justify-start gap-1">
              <div className="text-sm font-normal leading-tight text-frecBlack">
                {testimonial.name}
              </div>
              <div className="text-sm leading-tight text-frecBlack opacity-40">
                {testimonial.title}
              </div>
            </div>
          </div>
          <div className="self-stretch text-sm text-frecBlack">
            &ldquo;{testimonial.quote}&rdquo;
          </div>
        </div>
        <div className="inline-flex items-start justify-start gap-1">
          <div className="flex items-center text-sm font-medium text-frecfrecBlack">
            {testimonial.linkText} <AnimatedIcon />
          </div>
        </div>
      </div>
    </Link>
  );
};
