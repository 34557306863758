import { FC, PropsWithChildren } from "react";

import { classNames } from "../utils/classNames";

export const BubbleText: FC<PropsWithChildren<{ className?: string; dark?: boolean }>> = ({
  children,
  className,
  dark,
}) => {
  return (
    <span
      className={classNames(
        "inline-block border-2 rounded-full px-4 pb-1 pt-0 leading-tight",
        dark ? "text-frecNeon bg-frecBlack" : "bg-frecNeon border-[#242424]",
        className
      )}
    >
      {children}
    </span>
  );
};
