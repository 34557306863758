import { StaticImageData } from "next/image";

import Esther from "../public/webstatic/images/testimonials/esther-circle.png";
import Evan from "../public/webstatic/images/testimonials/evan-circle.png";
import Professor from "../public/webstatic/images/testimonials/professor-circle.png";
import Seb from "../public/webstatic/images/testimonials/seb-circle.png";

export const TestimonialCategory = "Case study";
export type Testimonial = {
  name: string;
  title: string;
  quote: string;
  linkText: string;
  image: string | StaticImageData;
  postId: number;
  url: string;
};
export const TestimonialList: Testimonial[] = [
  {
    name: "Evan M.",
    title: "Software Engineering Leader",
    quote:
      "My experience with Frec to date has really given me the confidence that my money is very well managed, and as a result I've been transferring more and more assets to Frec.",
    linkText: "Read more on Evan’s Frec journey",
    image: Evan,
    postId: 857,
    url: "meet-evan",
  },
  {
    name: "Esther C.",
    title: "Angel investor",
    quote:
      "I really appreciate that using Frec is as easy as putting my money in an ETF, but it also has this other benefit, which none of the other services that I use have: reducing my taxes.",
    linkText: "Read more on Esther’s Frec journey",
    image: Esther,
    postId: 923,
    url: "meet-esther",
  },
  {
    name: "Richard V.H.",
    title: "Professor",
    quote:
      "This is not some kind of fad, this is not just ‘this year's hot new product.’ This is something that really should be a permanent part of everybody's investment program.",
    linkText: "Read more on Richard’s Frec journey",
    image: Professor,
    postId: 869,
    url: "meet-richard",
  },
  {
    name: "Sebastian S.",
    title: "Chief Executive Officer",
    quote:
      "It's basically the cost of an ETF, except it's direct indexing. Frec is going to be the next big financial platform and I want to be a part of that.",
    linkText: "Read more on Sebastian’s Frec journey",
    image: Seb,
    postId: 863,
    url: "meet-sebastian",
  },
];
